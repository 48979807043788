.modal {
  display: none;
  position: fixed;
  z-index: 100000;
  padding-top: 100px;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0,0,0); 
  background-color: rgba(0,0,0,0.4);
}

.modal-content-close {
  display: flex;
  justify-content: flex-end;
}

.close {
  color: #232933;
  float: right;
  font-size: 28px;
  font-weight: bold;
  margin: 0;
}

.close:hover,
.close:focus {
  color: #DF5026;
  text-decoration: none;
  cursor: pointer;
}

.sale-content {
  background-color: #fff;
  padding: 0 20px 0px 20px;
  border-radius: 5px;
  width: 900px;
  height: 500px;
  position: relative;
  margin: auto;
}

.sale-img-background {
  background-image: url("/img/otvet32.jpg");
  width: 600px;
  height: 400px;
  background-repeat: no-repeat;
  background-size: cover;
  position: absolute;
  margin: 55px 0 0 0;
}

.sale-container {
  display: flex;
  flex-direction: row;
}

.sale-left {
  display: flex;
  flex-direction: column;
  width: 660px;
  z-index: 100;
  padding-top: 50px;
}

.sale-text {
  width: 560px;
  margin-left: 20px;
}

.sale-text p {
  color: #fff;
  font-weight: bold;
  font-size: 26px;
  background-color: rgba(0,0,0,.5);
  padding: 0 5px;
  margin-top: 100px;
  display: inline-block;
  text-align: center;
}

.sale-right {
  z-index: 100;
  display: flex;
  flex-direction: column;
  width: 240px;
}

.sale-title {
  color: #000;
  font-weight: bold;
  font-size: 18px;
  text-align: center;
  line-height: 1.3;
  margin: 0;
}

.sale-timer-title {
  color: #000;
  margin: 0;
  font-size: 14px;
  padding: 0 5px;
  margin-top: 5px;
  text-align: center;
}

.sale-timer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 5px;
}

.sale-timer-box {
  width: 60px;
  height: 70px;
}

.sale-timer-fon {
  width: 60px;
  height: 50px;
  border-radius: 5px;
  background-color: #232933;
}

.sale-timer-fon p {
  color: #fff;
  font-weight: bold;
  font-size: 30px;
  text-align: center;
  padding-top: 3px;
  margin: 0;
}

.sale-timer-text {
  text-align: center;
  font-size: 12px;
  color: #000;
  margin: 0;
}

.sale-razdel span {
  font-size: 36px;
  font-weight: bold;
  color: #c3c3ca;
  display: inline-block;
  margin-top: -5px;
  padding: 0 2px;
}

.sale-form input.contact-form__name {
  width: 230px;
  height: 35px;
  color: #848a90;
  font-size: 14px;
  border-style: none;
  background-color: #f5f6f6;
  border-radius: 5px;
  padding-left: 15px;
}

.sale-form input.contact-form__call {
  width: 230px;
  height: 35px;
  color: #848a90;
  font-size: 14px;
  border-style: none;
  background-color: #f5f6f6;
  border-radius: 5px;
  padding-left: 15px;
}

.sale-form .form-box input {
  margin: 5px;
}

.sale-form input.contact-form__name::placeholder {
  color: #848a90;
  font-size: 14px;
}

.sale-form input.contact-form__call::placeholder {
  color: #848a90;
  font-size: 14px;
}

.sale-form p.contact-form__policy {
  font-size: 10px;
  color: #575b5b;
  text-align: center;
}

.sale-form p.contact-form__policy a {
  font-size: 10px;
  color: #575b5b;
  text-decoration: underline;
}

.sale-form .contact-form__button {
  background-color: #b32933;
  color: #fff;
  width: 230px;
  height: 45px;
  font-size: 24px;
  font-weight: bold;
  border-style: none;
  border-radius: 5px;
  margin: 0 auto;
  margin: 0 5px 0 5px;
}

.sale-close {
  background-color: #232933;
  color: #fff;
  width: 230px;
  height: 35px;
  font-size: 14px;
  font-weight: bold;
  border-style: none;
  margin-top: 5px;
  font-weight: bold;
  border-radius: 5px;
  margin: 0 auto;
  margin-top: 5px;
}

/*==========  Media ==========*/
@media (min-width: 768px) and (max-width: 991.98px) { 
  .sale-content {
    background-color: #fff;
    padding: 0 20px 0px 20px;
    border-radius: 5px;
    width: 768px;
    height: 500px;
    position: relative;
    margin: auto;
  }

  .sale-left {
    display: flex;
    flex-direction: column;
    width: 528px;
    z-index: 100;
    padding-top: 50px;
  }

  .sale-img-background {
    width: 480px;
    height: 400px;
    background-repeat: no-repeat;
    background-size: cover;
    position: absolute;
    margin: 55px 0 0 0;
  }

  .sale-text {
    width: 440px;
    margin-left: 20px;
  }

  .sale-right {
    z-index: 100;
    display: flex;
    flex-direction: column;
    width: 240px;
  }
}