/* Menu */
.menu {
  height: 100%;
}

ul.menu-list {
  display: flex;
  flex-direction: row;
  height: 100%;
}

li.menu-item {
  display: flex;
  flex-direction: column;
  padding: 0 5px 0 5px;
  height: 100%;
  align-items: center;
  justify-content: center;
  border-left: 1px solid #E4E4E4;
}

li.menu-item a {
  text-align: center;
  text-decoration: none;
  font-size: 13px;
  color: #e8522f;
  margin-top: 5px;
}

/*==========  Media Header  ==========*/
@media (min-width: 1025px) and (max-width: 1280px) {
  li.menu-item {
    padding: 0 10px 0 10px;
  }

  li.menu-item a {
    font-size: 12px;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  li.menu-item {
    padding: 0 10px 0 10px;
  }

  li.menu-item a {
    font-size: 12px;
  }
}

@media (min-width: 481px) and (max-width: 767px) {
  .menu {
    display: none;
  }
}

@media (min-width: 320px) and (max-width: 480px) {
  .menu {
    display: none;
  }
}

@media (min-width: 0px) and (max-width: 319px) {
  .menu {
    display: none;
  }
}