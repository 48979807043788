.black_mod {
  color:black;
}

.white_mod {
  color: white;
}

.icon-powered-by {
  font-size: 1rem;
}

.icon-vk {
  font-size: 1rem;
}

.icon-facebook {
  font-size: 1rem;
}

.icon-instagram {
  font-size: 1rem;
}

.icon-menu {
  font-size: 1rem;
}

.icon-whatsapp {
  font-size: 1.5rem;
}

.icon-youtube {
  font-size: 2.3rem;
}

.icon-viber {
  font-size: 1.5rem;
}

.icon-telegram {
  font-size: 1.5rem;
}

.icon-done {
  font-size: 3rem;
  color: #e8522f;
}

.icon-qcheck-form {
  font-size: 1.5rem;
  color: #e8522f;
}

.icon-qleft-arrow {
  font-size: 1.2rem;
}

.icon-qcheck {
  font-size: 1.2rem;
}