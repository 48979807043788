html,
body {
  width: 100%;
  margin: 0;
  padding: 0;
}

body {
  font-family: "Open-Sans", "Tahoma", sans-serif;
  min-width: 320px;
}

img {
  max-width: 100%;
}

svg {
  overflow: hidden;
}

.red {
  color: #e8522f;
}

.silver {
  color: #b8b8b8;
}

.silver-line {
  background: #f4f4f4;
  width: 100%;
  height: 3px;
}

main {
  flex: auto;
}

.perezvon-right {
  position: fixed; 
  top: 25%; 
  right: -70px; 
  z-index: 1000; 
  transform: rotate(90deg); 
  font-size: 16px; 
  padding: 10px 15px; 
  color: #fff; 
  background:#e8522f; 
  border-radius: 0px 0px 5px 5px; 
  border-style: none;
}

.perezvon-right:hover {
  background: #c13715;
}

/* to top */
.to-top {
  width: 44px;
  height: 44px;
  background: #e8522f;
  position: fixed;
  right: 0px;
  bottom: 0px;
  z-index: 2;
}

a.uk-totop.uk-icon {
  color: #fff;
  margin-top: 10px;
  display: inline-block;
}