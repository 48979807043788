.main-company {
  max-width: 1200px;
  min-width: 320px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin-top: 50px;
  background: url("/img/fon2.jpg") center no-repeat;
  background-size: cover;
}

.company-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  margin-top: 50px;
}

.company-title {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 50px;
}

.company-title h2 {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: 0;
  font-weight: bold;
  font-size: 36px;
}

span.company-line {
  position: relative;
  display: block;
  overflow: visible;
  margin: 1em 0;
  padding: 0;
  height: 1px;
  border-top: 1px #eeeded solid;
}

span.company-line::before {
  background: #e7512f;
  position: absolute;
  top: -1px;
  left: 0;
  display: inline-block;
  width: 20%;
  height: 1px;
  content: "";
}

span.company-line::after {
  background: #e7512f;
  position: absolute;
  top: -1px;
  right: 0;
  display: inline-block;
  width: 20%;
  height: 1px;
  content: "";
}

.company-left {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 40%;
  padding: 10px;
}

.company-right {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 60%;
}

.company-right-content {
  display: flex;
  flex-direction: row;
  font-size: 18px;
}

.company-right-button {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.company-right-button button {
  margin: 0;
  font-weight: normal;
  width: 300px;
  height: 50px;
  font-size: 14px;
}

.company-button-2 {
  display: none;
}

.company-right-button button.d-slider-button.company-none {
  width: 350px;
}

@media (min-width: 481px) and (max-width: 767px) {
  .main-company {
    margin-top: 20px;
    background-image: none;
  }

  .company-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: 0px;
  }

  .company-title {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 20px;
  }

  .company-title h2 {
    font-size: 32px;
  }

  .company-left {
    display: none;
  }

  .company-right {
    width: 100%;
  }

  .company-right-content {
    font-size: 16px;
    padding: 10px;
  }

  .company-right-button {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-bottom: 10px;
    display: none;
  }

  .company-right-button button {
    margin: 0;
    font-weight: normal;
    width: 300px;
    height: 45px;
    font-size: 14px;
  }

  .company-right-button-2 {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-bottom: 10px;
  }

  .company-right-button-2 button {
    margin: 0;
    font-weight: normal;
    width: 300px;
    height: 45px;
    font-size: 14px;
    border-style: none;
  }

  .company-button-2 {
    display: block;
    background: #e7512f;
    color: #fff;
  }
}

@media (min-width: 320px) and (max-width: 480px) {
  .main-company {
    margin-top: 20px;
    background-image: none;
  }

  .company-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: 0px;
    padding-left: 10px;
    padding-right: 10px;
  }

  .company-title {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 20px;
  }

  .company-title h2 {
    font-size: 24px;
  }

  .company-left {
    display: none;
  }

  .company-right {
    width: 100%;
  }

  .company-right-content {
    font-size: 16px;
    padding: 10px;
  }

  .company-right-button {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-bottom: 10px;
    display: none;
  }

  .company-right-button button {
    margin: 0;
    font-weight: normal;
    width: 300px;
    height: 45px;
    font-size: 14px;
  }

  .company-right-button-2 {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-bottom: 10px;
  }

  .company-right-button-2 button {
    margin: 0;
    font-weight: normal;
    width: 300px;
    height: 45px;
    font-size: 14px;
    border-style: none;
  }

  .company-button-2 {
    background: #e7512f;
    color: #fff;
    display: block;
  }
}