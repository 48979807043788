/* Slider */
.slider {
  width: 1000px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

h2.slider-box {
  width: 525px;
  height: 172px;
  font-size: 44px;
  font-weight: bold;
  background: #000;
  background-color: rgba(0,0,0,0.247059);
  padding: 5px;
  text-align: right;
  margin-left: 450px;
}

.slider-content {
  width: 600px;
  font-size: 18px;
  font-weight: bold;
  color: #fff;
  background: #e8522f;
  background-color: rgba(232,82,47,0.8);
  padding: 5px;
  text-align: right;
  margin-left: 375px;
}

/* Button form */
button.d-slider-button {
  background-color: #e8522f;
  color: #fff;
  padding: 10px 15px;
  width: 350px;
  height: 50px;
  border-style: none;
  font-size: 15px;
  font-weight: bold;
  transform: skewX(160deg);
  display: inline-block;
  margin-left: 600px;

  background-size: 200% 100%,auto,100% 2px,100% 2px,100% 1px,100% 1px;
  background-image: linear-gradient(45deg,rgba(255,255,255,.0) 30%,rgba(255,255,255,.8),rgba(255,255,255,.0) 70%);
  background-repeat: no-repeat;
  background-position: -200% 0,0 0,0 0,0 100%,0 4px,0 calc(100% - 4px);
  animation: line 3s ease reverse infinite;
}


@keyframes line {
  100% {
    background-position: 500% 0,0 0,0 0,0 100%,0 4px,0 calc(100% - 4px);
  }

  20% {
    background-position: 200% 0,0 0,0 0,0 100%,0 4px,0 calc(100% - 4px);
  }

  0% {
    background-position: -200% 0,0 0,0 0,0 100%,0 4px,0 calc(100% - 4px);
  }
}

button.d-slider-button:hover {
  background: #c13715;
}

span.d-button-naklon {
  display: inline-block;
  transform: skewX(-160deg);
}

/* button slider*/
.uk-position-center-left.uk-position-small.uk-hidden-hover.uk-slidenav-previous.uk-icon.uk-slidenav {
  width: 45px;
  height: 50px;
  background: #fff;
  transform: skewX(-160deg);
  display: inline-block;
}

.uk-position-center-left.uk-position-small.uk-hidden-hover.uk-slidenav-previous.uk-icon.uk-slidenav svg {
  transform: skewX(160deg);
  display: inline-block;
  margin-top: 10px;
  margin-left: 5px;
  color: #ccc;
}

.uk-position-center-left.uk-position-small.uk-hidden-hover.uk-slidenav-previous.uk-icon.uk-slidenav:hover {
  background: #e8522f;
}

.uk-position-center-left.uk-position-small.uk-hidden-hover.uk-slidenav-previous.uk-icon.uk-slidenav:hover svg {
  color: #fff;
}

.uk-position-center-right.uk-position-small.uk-hidden-hover.uk-slidenav-next.uk-icon.uk-slidenav {
  width: 45px;
  height: 50px;
  background: #fff;
  transform: skewX(160deg);
  display: inline-block;
}

.uk-position-center-right.uk-position-small.uk-hidden-hover.uk-slidenav-next.uk-icon.uk-slidenav svg {
  transform: skewX(-160deg);
  display: inline-block;
  margin-top: 10px;
  margin-left: 10px;
  color: #ccc;
}

.uk-position-center-right.uk-position-small.uk-hidden-hover.uk-slidenav-next.uk-icon.uk-slidenav:hover {
  background: #e8522f;
}

.uk-position-center-right.uk-position-small.uk-hidden-hover.uk-slidenav-next.uk-icon.uk-slidenav:hover svg {
  color: #fff;
}

/* 2 slide */
h2.slider-box-b {
  width: 525px;
  height: 172px;
  font-size: 44px;
  font-weight: bold;
  background: #000;
  background-color: rgba(0,0,0,0.247059);
  padding: 5px;
  text-align: left;
  margin-left: 0px;
}

.slider-content-b {
  width: 600px;
  font-size: 18px;
  font-weight: bold;
  color: #fff;
  background: #e8522f;
  background-color: rgba(232,82,47,0.8);
  padding: 5px;
  text-align: left;
  margin-left: 0px;
}

/* Button form */
button.d-slider-button-b {
  background-color: #e8522f;
  color: #fff;
  padding: 10px 15px;
  width: 350px;
  height: 50px;
  border-style: none;
  font-size: 15px;
  font-weight: bold;
  transform: skewX(160deg);
  display: inline-block;
  margin-left: 50px;

  background-size: 200% 100%,auto,100% 2px,100% 2px,100% 1px,100% 1px;
  background-image: linear-gradient(45deg,rgba(255,255,255,.0) 30%,rgba(255,255,255,.8),rgba(255,255,255,.0) 70%);
  background-repeat: no-repeat;
  background-position: -200% 0,0 0,0 0,0 100%,0 4px,0 calc(100% - 4px);
  animation: line 3s ease reverse infinite;
}

button.d-slider-button-b:hover {
  background: #c13715;
}

/* 3slider */
h2.slider-box-c {
  width: 525px;
  height: 172px;
  font-size: 44px;
  font-weight: bold;
  background: #000;
  background-color: rgba(0,0,0,0.247059);
  padding: 5px;
  text-align: right;
  margin-left: 450px;
  margin-top: 90px;
}

img.photo-black.uk-cover {
  display: none;
}

img.photo-white.uk-cover {
  display: black;
}

@media (min-width: 768px) and (max-width: 1024px) {
  h2.slider-box {
    width: 500px;
    height: 70px;
    font-size: 24px;
    padding: 5px;
    margin-top: 30px;
    margin-left: 230px;
  }

  .slider-content {
    width: 500px;
    font-size: 14px;
    padding: 5px;
    text-align: right;
    margin-left: 230px;
  }

  button.d-slider-button {
    padding: 5px 10px;
    width: 250px;
    height: 50px;
    font-size: 12px;
    margin-left: 425px;
  }

  /* 2 slide */
  h2.slider-box-b {
    width: 500px;
    height: 70px;
    font-size: 24px;
    padding: 5px;
    margin-left: 150px;
    margin-top: 10px;
  }

  .slider-content-b {
    width: 500px;
    font-size: 14px;
    padding: 5px;
    margin-left: 150px;
  }

  button.d-slider-button-b {
    padding: 5px 10px;
    width: 250px;
    height: 50px;
    font-size: 12px;
    margin-left: 200px;
  }

  h2.slider-box-c {
    width: 500px;
    height: 70px;
    font-size: 24px;
    padding: 5px;
    margin-top: 30px;
    margin-left: 230px;
  }
}

@media (min-width: 481px) and (max-width: 767px) {
  img.photo-white.uk-cover {
      display: none;
    }

    img.photo-black.uk-cover {
      display: block;
    } 

  .slider {
    width: 480px;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: hidden;
    margin: 0 auto;
  }

  /* 1 slide */
  h2.slider-box-b {
    width: 350px;
    height: 50px;
    font-size: 18px;
    padding: 5px;
    color: #fff;
    text-align: center;
    margin-top: 40px;
    font-weight: bold;
    background-color: transparent;
  }

  .slider-content-b {
    width: 350px;
    font-size: 14px;
    padding: 5px;
    margin-left: 0px;
    margin-bottom: 40px;
    color: #fff;
    text-align: center;
    background-color: transparent;
  }

  /* 2 slide */
  h2.slider-box {
    width: 350px;
    height: 50px;
    font-size: 18px;
    padding: 5px;
    color: #fff;
    text-align: center;
    margin-top: 40px;
    font-weight: bold;
    margin-left: 0px;
    background-color: transparent;
  }

  .slider-content {
    width: 350px;
    font-size: 14px;
    padding: 5px;
    margin-left: 0px;
    margin-bottom: 40px;
    color: #fff;
    text-align: center;
    background-color: transparent;
  }

  button.d-slider-button-b {
    display: none;
  }

  button.d-slider-button {
    display: none;
  }

  h2.slider-box-c {
    width: 300px;
    height: 35px;
    font-size: 16px;
    padding: 5px;
    margin-left: 355px;
    margin-top: 20px;
  }

  /* button slider*/
  .uk-position-center-left.uk-position-small.uk-hidden-hover.uk-slidenav-previous.uk-icon.uk-slidenav {
    display: none;
  }

  .uk-position-center-left.uk-position-small.uk-hidden-hover.uk-slidenav-previous.uk-icon.uk-slidenav svg {
    display: none;
  }

  .uk-position-center-right.uk-position-small.uk-hidden-hover.uk-slidenav-next.uk-icon.uk-slidenav {
    display: none;
  }

  .uk-position-center-right.uk-position-small.uk-hidden-hover.uk-slidenav-next.uk-icon.uk-slidenav svg {
    display: none;
  }
}

@media (min-width: 320px) and (max-width: 480px) {
    img.photo-white.uk-cover {
      display: none;
    }

    img.photo-black.uk-cover {
      display: block;
    } 

  .slider {
    width: 320px;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: hidden;
    margin: 0 auto;
  }

  /* 1 slide */
  h2.slider-box-b {
    width: 300px;
    height: 30px;
    font-size: 14px;
    padding: 5px;
    color: #fff;
    text-align: center;
    margin-top: 40px;
    font-weight: bold;
    background-color: transparent;
  }

  .slider-content-b {
    width: 300px;
    font-size: 12px;
    padding: 5px;
    margin-left: 0px;
    margin-bottom: 40px;
    color: #fff;
    text-align: center;
    background-color: transparent;
  }

  /* 2 slide */
  h2.slider-box {
    width: 300px;
    height: 30px;
    font-size: 14px;
    padding: 5px;
    color: #fff;
    text-align: center;
    margin-top: 40px;
    font-weight: bold;
    margin-left: 0px;
    background-color: transparent;
  }

  .slider-content {
    width: 300px;
    font-size: 10px;
    padding: 5px;
    margin-left: 0px;
    margin-bottom: 40px;
    color: #fff;
    text-align: center;
    background-color: transparent;
  }

  button.d-slider-button-b {
    display: none;
  }

  button.d-slider-button {
    display: none;
  }

  h2.slider-box-c {
    width: 300px;
    height: 30px;
    font-size: 14px;
    padding: 5px;
    color: #fff;
    text-align: center;
    margin-top: 40px;
    font-weight: bold;
    margin-left: 0px;
    background-color: transparent;
  }

  /* button slider*/
  .uk-position-center-left.uk-position-small.uk-hidden-hover.uk-slidenav-previous.uk-icon.uk-slidenav {
    display: none;
  }

  .uk-position-center-left.uk-position-small.uk-hidden-hover.uk-slidenav-previous.uk-icon.uk-slidenav svg {
    display: none;
  }

  .uk-position-center-right.uk-position-small.uk-hidden-hover.uk-slidenav-next.uk-icon.uk-slidenav {
    display: none;
  }

  .uk-position-center-right.uk-position-small.uk-hidden-hover.uk-slidenav-next.uk-icon.uk-slidenav svg {
    display: none;
  }

}
