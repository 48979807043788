/* advantages */
.main-advantages {
  max-width: 1200px;
  min-width: 320px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin-top: 10px;
  background: url("/img/kazan2.jpg") center no-repeat;
  background-size: cover;
}

.advantages-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  margin-top: 50px;
}

.advantages-title {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 50px;
}

.advantages-title h2 {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: 0;
  font-weight: bold;
  font-size: 36px;
}

span.advantages-line {
  position: relative;
  display: block;
  overflow: visible;
  margin: 1em 0;
  padding: 0;
  height: 1px;
  border-top: 1px #eeeded solid;
}

span.advantages-line::before {
  background: #e7512f;
  position: absolute;
  top: -1px;
  left: 0;
  display: inline-block;
  width: 20%;
  height: 1px;
  content: "";
}

span.advantages-line::after {
  background: #e7512f;
  position: absolute;
  top: -1px;
  right: 0;
  display: inline-block;
  width: 20%;
  height: 1px;
  content: "";
}

.advantages-left {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 70%;
  padding: 0 20px 20px 20px;
}

.advantages-right {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 30%;
}

.advantages-content {
  display: flex;
  flex-direction: row;
  font-size: 18px;
}

@media (min-width: 481px) and (max-width: 767px) {
  .main-advantages {
    margin-top: 0px;
    background-image: none;
  }

  .advantages-container {
    margin-top: 10px;
  }

  .advantages-title {
    margin-top: 20px;
  }

  .advantages-title h2 {
    font-size: 32px;
  }

  .advantages-left {
    width: 100%;
  }

  .advantages-right {
    display: none;
  }

  .advantages-content {
    font-size: 16px;
  }
}

@media (min-width: 320px) and (max-width: 480px) {
  .main-advantages {
    margin-top: 0px;
    background-image: none;
  }

  .advantages-container {
    margin-top: 10px;
  }

  .advantages-title {
    margin-top: 20px;
  }

  .advantages-title h2 {
    font-size: 24px;
  }

  .advantages-left {
    width: 100%;
  }

  .advantages-right {
    display: none;
  }

  .advantages-content {
    font-size: 16px;
  }
}

@media (min-width: 0px) and (max-width: 319px) {
  .main-advantages {
    margin-top: 10px;
    background-image: none;
  }

  .advantages-container {
    margin-top: 10px;
  }

  .advantages-title {
    margin-top: 20px;
  }

  .advantages-title h2 {
    font-size: 24px;
  }

  .advantages-left {
    width: 100%;
  }

  .advantages-right {
    display: none;
  }

  .advantages-content {
    font-size: 16px;
  }
}