html,
body {
  height: 100%;
  font-family: "Open-Sans", "Tahoma", sans-serif;
  background-color: #fff;
  font-size: 16px;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  flex: auto;
}

.social-msg-contaiter {
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: #ededed;
}

.social-msg-wraper {
  max-width: 1170px;
  min-width: 320px;
  margin: 0 auto;
  position: relative;
  display: flex;
  justify-content: center;
  top: 10%;
}

.social-msg-item {
  width: 375px;
  height: 600px;
  background-color: #fafafa;
  border-radius: 15px;
  box-shadow: 0 0.03rem 0.4rem 0 rgba(0, 0, 0, 0.06);
  position: relative;
}

.social-msg-header{
  position: relative;
  width: 100%;
  height: 210px;
}

.social-msg-header img {
  border-radius: 15px 15px 0 0;
  position: absolute;
  display: block;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}

.social-msg-header-content {
  position: relative;
  color: #fff;
  width: 300px;
  margin: 0 auto;
}

.social-msg-header-content h1 {
  margin: 0;
  font-weight: normal;
  font-size: 32px;
  padding-top: 40px;
  color: #fff;
}

.social-msg-header-content p {
  margin: 0;
  font-weight: normal;
  padding-top: 10px;
}

.social-msg-body {
  position: relative;
  background-color: #fff;
  border-radius: 15px;
  box-shadow: 0 0.03rem 0.4rem 0 rgba(0, 0, 0, 0.06);
  width: 345px;
  margin: 0 auto;
}

.social-msg-card {
  display: flex;
  flex-direction: column;
  margin-top: -50px;
}

.social-msg-card-header {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  padding-top: 20px;
  padding-left: 20px;
  padding-bottom: 20px;
}

.social-msg-card-logo img {
  width: 70px;
  height: 70px;
  border-radius: 50%;
}

.social-msg-card-content h5 {
  color: black;
  padding-top: 10px;
  padding-left: 10px;
  margin: 0;
}

.social-msg-card-content p {
  padding-left: 10px;
  margin: 0;
}

.social-msg-card-buttons {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  padding-bottom: 5px;
}

.social-msg-whatsapp {
  width: 310px;
  height: 50px;
  margin: 0 5px 5px 5px;
  border-style: none;
  background-color: #F40000;
  color: #fff;
  border-radius: 10px;
  cursor: pointer;
}

.social-msg-viber {
  width: 310px;
  height: 50px;
  margin: 10px 5px 5px 5px;
  border-style: none;
  background-color: #4E9AD8;
  color: #fff;
  border-radius: 10px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 15px;
}

.social-msg-vk {
  width: 150px;
  height: 50px;
  margin: 0 5px 5px 5px;
  border-style: none;
  background-color: #6288BF;
  color: #fff;
  border-radius: 10px;
  cursor: pointer;
}

.social-msg-telegram {
  width: 150px;
  height: 50px;
  margin: 0 5px 5px 5px;
  border-style: none;
  background-color: #3D99D6;
  color: #fff;
  border-radius: 10px;
  cursor: pointer;
}

.social-msg-footer {
  position: relative;
  width: 90px;
  margin: 0 auto;
  top: 240px;
}

.social-msg-footer a {
  color: #2392EC;
  display: block;
}

@media (min-width: 320px) and (max-width: 480.98px) {
  .social-msg-wraper {
    top: 20px;
  }

  .social-msg-item {
    width: 305px;
    height: 600px;
  }
  
  .social-msg-header-content {
    position: relative;
    color: #fff;
    width: 280px;
    margin: 0 auto;
  }
  
  .social-msg-body {
    position: relative;
    background-color: #fff;
    border-radius: 15px;
    box-shadow: 0 0.03rem 0.4rem 0 rgba(0, 0, 0, 0.06);
    width: 280px;
    margin: 0 auto;
  }
  
  .social-msg-whatsapp {
  width: 310px;
  height: 50px;
  margin: 0 5px 5px 5px;
  border-style: none;
  background-color: #F40000;
  color: #fff;
  border-radius: 10px;
  cursor: pointer;
}

.social-msg-viber {
  width: 310px;
  height: 50px;
  margin: 10px 5px 5px 5px;
  border-style: none;
  background-color: #4E9AD8;
  color: #fff;
  border-radius: 10px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 15px;
}
  
  .social-msg-vk {
    width: 80px;
    height: 50px;
    margin: 0 5px 5px 5px;
    border-style: none;
    background-color: #6288BF;
    color: #fff;
    border-radius: 10px;
    cursor: pointer;
  }
  
  .social-msg-footer {
    position: relative;
    width: 90px;
    margin: 0 auto;
    top: 240px;
  }
}
