.swiper-container {
  max-width: 1200px;
  min-width: 320px;
  height: 320px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  margin-bottom: 40px;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}

.swiper-button-prev, .swiper-container-rtl .swiper-button-next {
  background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIj8+CjxzdmcgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgdmVyc2lvbj0iMS4xIiBpZD0iQ2FwYV8xIiB4PSIwcHgiIHk9IjBweCIgdmlld0JveD0iMCAwIDQ3Ny4xNzUgNDc3LjE3NSIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgNDc3LjE3NSA0NzcuMTc1OyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSIgd2lkdGg9IjQ0cHgiIGhlaWdodD0iNDRweCI+PGc+PGc+Cgk8cGF0aCBkPSJNMTQ1LjE4OCwyMzguNTc1bDIxNS41LTIxNS41YzUuMy01LjMsNS4zLTEzLjgsMC0xOS4xcy0xMy44LTUuMy0xOS4xLDBsLTIyNS4xLDIyNS4xYy01LjMsNS4zLTUuMywxMy44LDAsMTkuMWwyMjUuMSwyMjUgICBjMi42LDIuNiw2LjEsNCw5LjUsNHM2LjktMS4zLDkuNS00YzUuMy01LjMsNS4zLTEzLjgsMC0xOS4xTDE0NS4xODgsMjM4LjU3NXoiIGRhdGEtb3JpZ2luYWw9IiMwMDAwMDAiIGNsYXNzPSJhY3RpdmUtcGF0aCIgZGF0YS1vbGRfY29sb3I9IiMwMDAwMDAiIGZpbGw9IiNFODUyMkYiLz4KPC9nPjwvZz4gPC9zdmc+Cg==);
}

.swiper-button-next, .swiper-container-rtl .swiper-button-prev {
  background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIj8+CjxzdmcgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgdmVyc2lvbj0iMS4xIiBpZD0iQ2FwYV8xIiB4PSIwcHgiIHk9IjBweCIgdmlld0JveD0iMCAwIDQ3Ny4xNzUgNDc3LjE3NSIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgNDc3LjE3NSA0NzcuMTc1OyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSIgd2lkdGg9IjQ0cHgiIGhlaWdodD0iNDRweCI+PGc+PGc+Cgk8cGF0aCBkPSJNMzYwLjczMSwyMjkuMDc1bC0yMjUuMS0yMjUuMWMtNS4zLTUuMy0xMy44LTUuMy0xOS4xLDBzLTUuMywxMy44LDAsMTkuMWwyMTUuNSwyMTUuNWwtMjE1LjUsMjE1LjUgICBjLTUuMyw1LjMtNS4zLDEzLjgsMCwxOS4xYzIuNiwyLjYsNi4xLDQsOS41LDRjMy40LDAsNi45LTEuMyw5LjUtNGwyMjUuMS0yMjUuMUMzNjUuOTMxLDI0Mi44NzUsMzY1LjkzMSwyMzQuMjc1LDM2MC43MzEsMjI5LjA3NXogICAiIGRhdGEtb3JpZ2luYWw9IiMwMDAwMDAiIGNsYXNzPSJhY3RpdmUtcGF0aCIgZGF0YS1vbGRfY29sb3I9IiMwMDAwMDAiIGZpbGw9IiNFODUyMkYiLz4KPC9nPjwvZz4gPC9zdmc+Cg==);
}

@media (min-width: 992px) and (max-width: 1199.98px) {
  .swiper-container {
    padding: 0 20px;
  }

  .swiper-button-prev, .swiper-container-rtl .swiper-button-next {
    margin-left: 20px;
  }

  .swiper-button-next, .swiper-container-rtl .swiper-button-prev {
    margin-right: 20px;
  }
}

@media (min-width: 768px) and (max-width: 991.98px) { 
  .swiper-container {
    max-width: 768px;
    padding: 0 20px;
  }

  .swiper-button-prev, .swiper-container-rtl .swiper-button-next {
    margin-left: 20px;
  }

  .swiper-button-next, .swiper-container-rtl .swiper-button-prev {
    margin-right: 20px;
  }
}

@media (min-width: 576px) and (max-width: 767.98px) { 
  .swiper-container {
    max-width: 575px;
    padding: 0 20px;
  }

  .swiper-button-prev, .swiper-container-rtl .swiper-button-next {
    margin-left: 20px;
  }

  .swiper-button-next, .swiper-container-rtl .swiper-button-prev {
    margin-right: 20px;
  }
}

@media (min-width: 481px) and (max-width: 575.98px) {
  .swiper-container {
    max-width: 480px;
    padding: 0 20px;
  }

  .swiper-button-prev, .swiper-container-rtl .swiper-button-next {
    margin-left: 20px;
  }

  .swiper-button-next, .swiper-container-rtl .swiper-button-prev {
    margin-right: 20px;
  }
}

@media (min-width: 320px) and (max-width: 480.98px) {
  .swiper-container {
    max-width: 480px;
    padding: 0 20px;
  }

  .swiper-button-prev, .swiper-container-rtl .swiper-button-next {
    margin-left: 20px;
  }

  .swiper-button-next, .swiper-container-rtl .swiper-button-prev {
    margin-right: 20px;
  }
}


