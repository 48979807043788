.services-mobile__list {
  display: none;
}

.main-services {
  max-width: 1200px;
  min-width: 320px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin-top: 10px;
}

.services-title {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 20px;
}

.services-title h2 {
  font-weight: bold;
  display: flex;
  justify-content: center;
  margin: 0;
}

.button-list {
  display: none;
}

/* line style */
hr.hr-style {
  position: relative;
  display: block;
  overflow: visible;
  margin: 1em 0;
  padding: 0;
  height: 1px;
  border-top: 1px #eeeded solid;
}

hr.hr-style::before {
  position: absolute;
  top: -1px;
  left: 20%;
  display: inline-block;
  width: 20%;
  height: 1px;
  background: #e7512f;
  content: "";
}

hr.hr-style::after {
  position: absolute;
  top: -1px;
  right: 20%;
  display: inline-block;
  width: 20%;
  height: 1px;
  content: "";
  background: #e7512f;
}

/* services list */
.services-list {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
  background: url("/img/kazan.jpg") center no-repeat;
  background-size: cover;
}

.services-item {
  width: 320px;
  display: flex;
  flex-direction: column;
  padding-top: 20px;
}

.services-img {
  width: 320px;
  height: 210px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 10px;
}

/* Figura */
a.services-figura {
  position: relative;
  color: #fff;
  background: #e7512f;
  height: 50px;
  width: 270px;
  text-align: center;
  line-height: 50px;
  margin: auto;
  display: block;
}

a.services-figura:before {
  content: '';
  position: absolute;
  left: 0;
  margin-left: -55px;
  border: 25px solid transparent;
  border-right: 30px solid #e7512f;
}

a.services-figura:after {
  content: '';
  position: absolute;
  right: 0;
  margin-right: -55px;
  border: 25px solid transparent;
  border-left: 30px solid #e7512f;
}

.services-button {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.services-style-button {
  height: 45px;
  font-size: 14px;
  border-radius: 0px;
  margin-top: 10px;
}


a.services-style-a {
  font-size: 14px;
  background: #e8522f;
  color: #fff;
  text-decoration: none;
  margin-top: 5px;
  padding: 13px 35px 12px 35px;
}

a.services-style-a:hover {
  color: #fff;
  background: #c13715;
}

/* Modal services */
.modal-header {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: baseline;
}

.modal-header h2 {
  margin: 0;
  font-weight: bold;
}

.modal-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
}

.modal-slider {
  display: flex;
  flex-direction: column;
  width: 50%;
}

.modal-content {
  display: flex;
  flex-direction: column;
  width: 50%;
  text-align: center;
}

.otvet-full {
  width: 438px;
  height: 315px;
}

.modal-content h3 {
  margin: 0;
}

.modal-big-photo {
  display: flex;
  flex-direction: row;
  justify-content: center; 
}

/* modal photo */
.otvet-all {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.otvet-all img {
  padding: 5px;
  width: 80px; 
  height: 57px;
}

.paletirovanie-all {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.paletirovanie-all img {
  padding: 5px;
  width: 80px; 
  height: 57px;
}

.melk-all {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.melk-all img {
  padding: 5px;
  width: 80px; 
  height: 57px;
}

.mebel-all {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.mebel-all img {
  padding: 5px;
  width: 80px; 
  height: 57px;
}

.arhiv-all {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.arhiv-all img {
  padding: 5px;
  width: 80px; 
  height: 57px;
}

/* Modal form */
.modal-form {
  margin-top: 20px;
}

.modal-form button {
  margin-top: 10px;
}

.services-style-button {
  background: #e8522f;
  color: #fff;
  padding: 10px 15px;
  border-style: none;
  width: 100%;
  margin: 5px;
  width: 240px;
}

.services-style-input {
  background: #fff;
  border-style: none;
  padding: 10px 15px;
  border: 2px solid #ccc;
  width: 200px;
  margin: 5px;
}

.services__policy {
  width: 280px;
  font-size: 11px;
  margin: 0 auto;
}

.services__policy a {
  text-decoration: none;
  color: #e8522f;
}

/* 768 */
@media (min-width: 768px) and (max-width: 1024px) {
  .services-style-button {
    background: #e8522f;
    color: #fff;
    padding: 10px 15px;
    border-style: none;
    width: 100%;
    margin: 5px;
    width: 240px;
  }

  .services-style-input {
    background: #fff;
    border-style: none;
    padding: 10px 15px;
    border: 2px solid #ccc;
    width: 200px;
    margin: 5px;
  }

  .services-item {
    width: 320px;
    padding: 20px;
  }

  .modal-header {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
  }

  .modal-header h2 {
    display: flex;
    flex-direction: row;
    justify-content: center;
    font-size: 22px;
  }

  .otvet-full {
    width: 270px;
    height: 180px;
  }

  .paletirovanie-full {
    width: 270px;
    height: 180px;
  }

  .melk-full {
    width: 270px;
    height: 180px;
  }

  .mebel-full {
    width: 270px;
    height: 180px;
  }

  .arhiv-full {
    width: 270px;
    height: 180px;
  }

  .modal-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .modal-slider {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .modal-content {
    display: flex;
    flex-direction: column;
    width: 100%;
    text-align: center;
  }
}

/* 481 */
@media (min-width: 481px) and (max-width: 767px) {
  .services-style-button {
    background: #e8522f;
    color: #fff;
    padding: 10px 15px;
    border-style: none;
    width: 100%;
    margin: 5px;
    width: 240px;
  }

  .services-style-input {
    background: #fff;
    border-style: none;
    padding: 10px 15px;
    border: 2px solid #ccc;
    width: 200px;
    margin: 5px;
  }

  .services-list {
    display: none;
  }

  .services-mobile__list {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    flex-wrap: wrap;
  }

  .services-mobile__item {
    width: 320px;
    padding: 15px;
    border: 1px solid #e7512f;
    margin: 10px;
  }

  .services-mobile__title {
    display: flex;
    justify-content: center;
    text-transform: uppercase;
    margin-top: 10px;
  }

  .services-mobile__title a {
    color: #000;
    font-size: 22px;
    font-weight: bold;
    text-decoration: none;
  }

  a.services-mobile__title-small {
    font-size: 16px;
  }

  a.services-mobile__title-small-2 {
    font-size: 14px;
  }

  .services-mobile__title a:after {
    content: "";
    display: block;
    margin: 0 auto;
    padding-top: 5px;
    border-bottom: 1px solid #e7512f;
  }

  .services-mobile__buttons {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    flex-wrap: wrap;
    padding: 5px;
  }

  .services-mobile__button-one {
    width: 140px;
    background: #fff;
    border: 1px solid #e7512f;
    padding: 10px 0px;
    color: #e7512f;
    font-size: 12px;
    margin-top: 10px;
  }

  .services-mobile__button-one:hover {
    background: #e7512f;
    border: 1px solid #e7512f;
    color: #fff;
  }

  .services-mobile__button-two {
    width: 140px;
    background: #e7512f;
    border: 1px solid #e7512f;
    padding: 10px 0px;
    color: #fff;
    font-size: 12px;
    margin-top: 10px;
  }

  .services-mobile__button-two:hover {
    background: #fff;
    border: 1px solid #e7512f;
    color: #e7512f;
  }

  /* modal */
  .modal-header {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
  }

  .modal-header h2 {
    display: flex;
    flex-direction: row;
    justify-content: center;
    font-size: 22px;
  }

  .otvet-full {
    width: 270px;
    height: 180px;
  }

  .paletirovanie-full {
    width: 270px;
    height: 180px;
  }

  .melk-full {
    width: 270px;
    height: 180px;
  }

  .mebel-full {
    width: 270px;
    height: 180px;
  }

  .arhiv-full {
    width: 270px;
    height: 180px;
  }

  .modal-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .modal-slider {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .modal-content {
    display: flex;
    flex-direction: column;
    width: 100%;
    text-align: center;
  }
}


/* 320 */
@media (min-width: 320px) and (max-width: 480px) {
  .services-style-button {
    background: #e8522f;
    color: #fff;
    padding: 10px 15px;
    border-style: none;
    width: 100%;
    margin: 5px;
    width: 220px;
  }

  .services-style-input {
    background: #fff;
    border-style: none;
    padding: 10px 15px;
    border: 2px solid #ccc;
    width: 220px;
    margin: 5px;
  }

  .services-list {
    display: none;
  }

  .services-mobile__list {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    flex-wrap: wrap;
  }

  .services-mobile__item {
    width: 320px;
    padding: 15px;
    border: 1px solid #e7512f;
    margin: 10px;
  }

  .services-mobile__title {
    display: flex;
    justify-content: center;
    text-transform: uppercase;
    margin-top: 10px;
  }

  .services-mobile__title a {
    color: #000;
    font-size: 22px;
    font-weight: bold;
    text-decoration: none;
  }

  a.services-mobile__title-small {
    font-size: 16px;
  }

  a.services-mobile__title-small-2 {
    font-size: 14px;
  }

  .services-mobile__title a:after {
    content: "";
    display: block;
    margin: 0 auto;
    padding-top: 5px;
    border-bottom: 1px solid #e7512f;
  }

  .services-mobile__buttons {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    flex-wrap: wrap;
    padding: 5px;
  }

  .services-mobile__button-one {
    width: 140px;
    background: #fff;
    border: 1px solid #e7512f;
    padding: 10px 0px;
    color: #e7512f;
    font-size: 12px;
    margin-top: 10px;
  }

  .services-mobile__button-one:hover {
    background: #e7512f;
    border: 1px solid #e7512f;
    color: #fff;
  }

  .services-mobile__button-two {
    width: 140px;
    background: #e7512f;
    border: 1px solid #e7512f;
    padding: 10px 0px;
    color: #fff;
    font-size: 12px;
    margin-top: 10px;
  }

  .services-mobile__button-two:hover {
    background: #fff;
    border: 1px solid #e7512f;
    color: #e7512f;
  }

/* modal */
  .modal-header {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
  }

  .modal-header h2 {
    display: flex;
    flex-direction: row;
    justify-content: center;
    font-size: 22px;
  }

  .otvet-full {
    width: 270px;
    height: 180px;
  }

  .paletirovanie-full {
    width: 270px;
    height: 180px;
  }

  .melk-full {
    width: 270px;
    height: 180px;
  }

  .mebel-full {
    width: 270px;
    height: 180px;
  }

  .arhiv-full {
    width: 270px;
    height: 180px;
  }

  .modal-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .modal-slider {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .modal-content {
    display: flex;
    flex-direction: column;
    width: 100%;
    text-align: center;
  }

  .services__policy {
    width: 220px;
  }
}
/* end 320 */
