.main-partners {
  max-width: 1200px;
  min-width: 320px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 10px;
}

.partners-container {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  flex-wrap: wrap;
}

.contact-content {
  min-height: 230px;
  padding: 5px;
}

.contact-size {
  color: #000;
  font-size: 14px;
  max-width: 500px;
  min-width: 320px; 
}

.contact-map {
  width: 500px;
  height: 350px;
  margin-top: 20px;
}

.contact-size p {
  margin: 0;
  margin-top: 5px;
}

.partners-title {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 50px;
}

.partners-title h2 {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: 0;
  font-weight: bold;
  font-size: 36px;
}

span.partners-line {
  position: relative;
  display: block;
  overflow: visible;
  margin: 1em 0;
  padding: 0;
  height: 1px;
  border-top: 1px #eeeded solid;
}

span.partners-line::before {
  background: #e7512f;
  position: absolute;
  top: -1px;
  left: 0;
  display: inline-block;
  width: 20%;
  height: 1px;
  content: "";
}

span.partners-line::after {
  background: #e7512f;
  position: absolute;
  top: -1px;
  right: 0;
  display: inline-block;
  width: 20%;
  height: 1px;
  content: "";
}

.photo-trans-vektor {
  width: 240px;
  height: 60px;
  margin-bottom: 20px;
}

.contact-size h4 {
  margin: 0;
  margin-top: 20px;
}

.uk-tab>.uk-active>a {
  color: #333;
  border-color: #e7512f;
}

@media (min-width: 320px) and (max-width: 480px) {
  .partners-title {
    margin-top: 10px;
  }

  .partners-title h2 {
    font-size: 32px;
  }

  .contact-size {
    font-size: 14px;
  }

  .contact-map {
    margin-top: 20px;
  }

  .contact-size {
    max-width: 100%;
    min-width: 100%; 
  }

  .contact-map {
    width: 100%;
    height: 350px;
  }
}

@media (min-width: 0px) and (max-width: 319px) {
  .partners-title {
    margin-top: 10px;
  }

  .partners-title h2 {
    font-size: 32px;
  }

  .contact-size {
    font-size: 14px;
  }

  .contact-map {
    margin-top: 20px;
  }

  .contact-size {
    max-width: 100%;
    min-width: 100%; 
  }

  .contact-map {
    width: 100%;
    height: 350px;
  }
}