@font-face {
  font-family: "Open-Sans";
  src: url("../fonts/opensans.woff2") format("woff2"), url("../fonts/opensans.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Open-Sans";
  src: url("../fonts/opensansbold.woff2") format("woff2"), url("../fonts/opensansbold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Open Sans";
  src:
    local("Open Sans Light"),
    url("../fonts/opensanslight.woff2") format("woff2"),
    url("../fonts/opensanslight.woff") format("woff");
  font-display: swap;
  font-style: normal;
  font-weight: 300;
}
