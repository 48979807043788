/* map */
.main-map {
  width: 100%;
  height: 450px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.map-contact {
  display: flex;
  justify-content: flex-end;
  max-width: 1200px;
  min-width: 320px;
}

.map-contact img {
  position: absolute;
  z-index: 1;
  width: 358px;
  height: 214px;
  right: 0px;
}

@media (min-width: 481px) and (max-width: 767px) {
  .map-contact img {
    position: absolute;
    z-index: 1;
    width: 179px;
    height: 107px;
    right: 0px;
  }
}

@media (min-width: 320px) and (max-width: 480px) {
  .map-contact img {
    position: absolute;
    z-index: 1;
    width: 179px;
    height: 107px;
    right: 0px;
  }
}

@media (min-width: 0px) and (max-width: 319px) {
  .map-contact img {
    position: absolute;
    z-index: 1;
    width: 179px;
    height: 107px;
    right: 0px;
  }
}