/* Modal */
.modal-contact-form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  align-items: center;
}

.modal-contact-form input {
  margin-top: 10px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 240px;
}

.modal-contact-form button {
  margin-top: 10px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 240px;
  margin: 0;
}

.modal-container {
  display: flex;
  justify-content: center;
  flex-direction: row;
}

.modal-style-button {
  background: #e8522f;
  color: #fff;
  padding: 10px 15px;
  border-style: none;
  margin: 0;
  width: 200px;
  display: flex;
  justify-content: center;
  flex-direction: row;
}

.modal__policy {
  width: 240px;
  font-size: 11px;
  margin: 0;
  padding-bottom: 5px;
  text-align: center;
}

.modal_policy a {
  text-decoration: none;
  color: #e8522f;
}

@media (min-width: 320px) and (max-width: 480px) {
  h2.uk-modal-title.red.uk-text-center {
    font-size: 22px;
    margin: 0;
  }
}

@media (min-width: 0px) and (max-width: 319px) {
  h2.uk-modal-title.red.uk-text-center {
    font-size: 22px;
    margin: 0;
  }
}