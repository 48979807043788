.insta-container {
  width: 900px;
}

.insta-list {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
  background: url("/img/kazan2.jpg") center no-repeat;
  background-size: cover;
}

.insta-item {
  margin: 20px;
  width: 280px;
  height: 280px;
  cursor: pointer;
  position: relative;
}

.insta-item:hover .overlay {
  opacity: 1;
}

.insta-item img {
  width: 280px;
  height: 280px;
}

.insta-img {
  display: block;
  width: 100%;
  height: auto;
}

.overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  transition: .5s ease;
  background-color: #e8522f;
}

.text {
  color: white;
  font-size: 20px;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  text-align: center;
}

// slider modal
.modali1 {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.modali1 img {
  padding: 5px;
  width: 84px; 
  height: 84px;
}

.modali1-full {
  width: 350px;
  height: 350px;
}

//2
.modali2 {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.modali2 img {
  padding: 5px;
  width: 84px; 
  height: 84px;
}

.modali2-full {
  width: 350px;
  height: 350px;
}

//3
.modali3 {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.modali3 img {
  padding: 5px;
  width: 84px; 
  height: 84px;
}

.modali3-full {
  width: 350px;
  height: 350px;
}

//4
.modali4 {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.modali4 img {
  padding: 5px;
  width: 84px; 
  height: 84px;
}

.modali4-full {
  width: 350px;
  height: 350px;
}

//5
.modali5 {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.modali5 img {
  padding: 5px;
  width: 84px; 
  height: 84px;
}

.modali5-full {
  width: 350px;
  height: 350px;
}

//6
.modali6 {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.modali6 img {
  padding: 5px;
  width: 84px; 
  height: 84px;
}

.modali6-full {
  width: 350px;
  height: 350px;
}

@media (min-width: 320px) and (max-width: 480.98px) {
  .mobile-hiden {
    display: none;
  }
}
