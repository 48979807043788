/* price */
.main-price {
  max-width: 1200px;
  min-width: 320px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.price-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
}

.price-title {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.price-title h2 {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: 0;
  font-weight: bold;
  font-size: 36px;
}

span.price-line {
  position: relative;
  display: block;
  overflow: visible;
  margin: 1em 0;
  padding: 0;
  height: 1px;
  border: 0;
  border-top: 1px #eeeded solid;
}

span.price-line::before {
  background: #e7512f;
  position: absolute;
  top: -1px;
  left: 0;
  display: inline-block;
  width: 20%;
  height: 1px;
  content: "";
  left: 30%;
  width: 40%;
}

/* left */
.price-left {
  display: flex;
  flex-direction: column;
  width: 80%;
}

.price-left {
  padding: 15px;
}

/* right */
.price-right {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 20%;
}

.price-office {
  width: 165px;
  height: 165px;
}

.price-right h4 {
  margin: 0;
  margin-top: 10px;
}

.price-right h5 {
  margin: 0;
  margin-top: 10px;
}

/* accordion */
.uk-accordion-title {
  background: #ccc;
  padding: 5px;
}

.uk-accordion-title::after {
  background-image: none;
}

.uk-open > .uk-accordion-title::after {
  background-image: none;
}

.price-info {
  background: #fff6bf;
  padding: 5px;
  color: #000;
  border: 1px solid #e0d594;
  font-size: 14px;
}

.price-info h5 {
  margin:0;
}

.price-info p {
  margin:0;
}

/* Tabl */
table {
  border-collapse: collapse;
  border-spacing: 0;
  border: 1px solid #ddd;
}

th, td {
  text-align: left;
  padding: 8px;
  border: 1px solid #ddd;
}

tr:nth-child(even) {
  background-color: #f2f2f2
}

th {
  background: #e7512f;
}

.uk-table th {
  color: white;
}

@media (min-width: 320px) and (max-width: 480px) {
  .price-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  /* left */
  .price-left {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 90%;
  }

  /* right */
  .price-right {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 90%;
  }

  img.price-office {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin: auto;
  }

  .price-right h4 {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }

  .price-right h5 {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
}
