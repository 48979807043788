.icon {
  display: inline-block;
  width: 1em;
  height: 1em;
  fill: currentColor;
}

.icon-done {
  font-size:(512/10)*1rem;
  width:(512/512)*1em;
}
.icon-facebook {
  font-size:(96.123/10)*1rem;
  width:(96.124/96.123)*1em;
}
.icon-instagram {
  font-size:(169.063/10)*1rem;
  width:(169.063/169.063)*1em;
}
.icon-menu {
  font-size:(341.33/10)*1rem;
  width:(341.33/341.33)*1em;
}
.icon-qcheck {
  font-size:(512/10)*1rem;
  width:(512/512)*1em;
}
.icon-qcheck-form {
  font-size:(512/10)*1rem;
  width:(512/512)*1em;
}
.icon-qleft-arrow {
  font-size:(512/10)*1rem;
  width:(512/512)*1em;
}
.icon-telegram {
  font-size:(512/10)*1rem;
  width:(512/512)*1em;
}
.icon-viber {
  font-size:(666.4/10)*1rem;
  width:(632/666.4)*1em;
}
.icon-vk {
  font-size:(304.36/10)*1rem;
  width:(304.36/304.36)*1em;
}
.icon-whatsapp {
  font-size:(512/10)*1rem;
  width:(512/512)*1em;
}
.icon-youtube {
  font-size:(512/10)*1rem;
  width:(512/512)*1em;
}
