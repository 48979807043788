/* footer */
footer {
  width: 100%;
  background: #e8522f;
  color: #000;
  font-size: 16px;
}

.footer-container {
  max-width: 1200px;
  min-width: 320px;
  margin: 0 auto;
  display: flex;
  justify-content: space-around;
  flex-direction: row;
  margin-top: 0px;
  padding-bottom: 20px;
}

.footer-left {
  margin-top: 20px;
  font-size: 24px;
  font-weight: bold;
}

.footer-left p {
  margin: 0px;
}

.footer-left a {
  font-weight: normal;
  font-size: 16px;
  color: #000;
  border-bottom: 2px dotted black;
}

.footer-center {
  margin-top: 20px;
  font-size: 24px;
  font-weight: bold;
}

.footer-center p {
  margin: 0px;
}

.footer-right {
  margin-top: 20px;
  color: #000;
}

.footer-right h5 {
  font-weight: bold;
  margin: 0px;
  color: #000;
}

.footer-right p {
  font-size: 14px;
  margin: 0px;
}

@media (min-width: 481px) and (max-width: 767px) {
  footer {
    font-size: 14px;
  }

  .footer-container {
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin-top: 0px;
    padding-bottom: 20px;
  }

  .footer-left {
    font-size: 18px;
    font-weight: bold;
    margin-left: 20px;
    margin-top: 20px;
  }

  .footer-left p {
    margin: 0px;
  }

  .footer-left a {
    font-weight: normal;
    font-size: 18px;
    color: #000;
    border-bottom: 2px dotted black;
  }

  .footer-center {
    font-size: 18px;
    margin-left: 20px;
    padding-top: 20px;
  }

  .footer-right {
    margin-left: 20px;
    padding-top: 20px;
  }

  .footer-right p {
    font-size: 14px;
    margin: 0px;
  }
}

@media (min-width: 320px) and (max-width: 480px) {
  footer {
    font-size: 14px;
  }

  .footer-container {
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin-top: 0px;
    padding-bottom: 20px;
  }

  .footer-left {
    font-size: 18px;
    font-weight: bold;
    margin-left: 20px;
    margin-top: 20px;
  }

  .footer-left p {
    margin: 0px;
  }

  .footer-left a {
    font-weight: normal;
    font-size: 18px;
    color: #000;
    border-bottom: 2px dotted black;
  }

  .footer-center {
    font-size: 18px;
    margin-left: 20px;
    padding-top: 20px;
  }

  .footer-right {
    margin-left: 20px;
    padding-top: 20px;
  }

  .footer-right p {
    font-size: 14px;
    margin: 0px;
  }
}
