.quiz-content {
  background-color: #fff;
  border-radius: 5px;
  width: 900px;
  height: 650px;
  position: relative;
  margin: auto;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.quize-close-button {
  width: 900px;
}

.quize-left {
  width: 900px;
  height: 100%;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  flex: auto;
}

.quize-left hr {
  margin: 0;
}

.quize-title {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 10px;
  margin-left: 30px;
  padding-bottom: 20px;
}

.quize-title h5 {
  color: #69748c;
  font-weight: 500;
  margin: 0;
}

svg.icon.icon-qcheck-form {
  margin-right: 10px;
}

.quize-progress-bar {
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  margin-left: 30px;
  margin-right: 30px;
}

.quize-progress-bar-title p {
  font-size: 12px;
  text-align: left;
  font-weight: 500;
  margin: 0;
}

.quize-progress-bar-title span {
  color: #e8522f;
  font-size: 14px;
  padding-left: 5px;
}

.quize-progress-bar-line {
  margin-top: 5px;
}

.quize-question {
  margin: 0;
  margin-top: 10px;
  margin-left: 30px;
  font-size: 28px;
  font-weight: 500;
  color: #363636;
}

/* Progress line */
@for $i from 0 through 100 {
  .progress progress[value="#{$i}"]
  {
    & + .progress-value:before { content : '#{$i}%' }
    & ~ .progress-bg .progress-bar { width: $i * 1% }
  }
}

.progress {
  position: relative;
  overflow: hidden;

progress {
  position: absolute;
  width: 0;
  height: 0;
  overflow: hidden;
  left: -777px;
}
}

.progress-value {
  color: #333;
  display: block;
  line-height: 21px;
  text-align: center;
}

.progress-bg {
  background: #e6e9ed;
  position: relative;
  height: 8px;
  border-radius: 5px;
  overflow: hidden;
}

@keyframes progress_bar {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: -40px 0;
  }
}

.progress-bar {
  overflow: hidden;
  background: #e8522f;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  transition: width 1s linear;    
}

.progress-bar:after {
  animation: progress_bar 0.8s linear infinite;      
  background-image: linear-gradient(45deg,rgba(255,255,255,.15) 25%,transparent 25%,transparent 50%,rgba(255,255,255,.15) 50%,rgba(255,255,255,.15) 75%,transparent 75%,transparent);
  background-size: 40px 40px;
  position: absolute;
  content: '';
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-repeat: repeat;
}

/* Progress line */
.quize-answers-container {
  flex: auto;
}

.quize-answers {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 20px 30px 20px 30px;
  width: 840px;
  flex: auto;
}

.quize-answer {
  display: flex;
  align-items: center;
  width: 410px;
  height: 50px;
  border: 1px solid rgba(217, 216, 230, 0.55);
  border-radius: 5px;
  padding: 10px;
  margin-bottom: 20px;
  cursor: pointer;
  user-select: none;
}

.quize-answer input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

span.quize-check {
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  background-color: #e8e8e8;
  border-radius: 50%;
  margin-left: 10px;
}

span.quize-answer-text {
  color: #232323;
  padding-left: 15px;
}

.quize-answer:hover input ~ .quize-check {
  background-color: #ccc;
}

.quize-answer input:checked ~ .quize-check {
  background-color: #e8522f;
}

.quize-check:after {
  content: "";
  position: absolute;
  display: none;
}

.quize-answer input:checked ~ .quize-check:after {
  display: block;
}

.quize-answer .quize-check:after {
  top: 9px;
  left: 9px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: white;
}

.quize-buttons {
  display: flex;
  justify-content: space-between;
  margin-left: 30px;
  margin-right: 30px;
  margin-bottom: 60px;
}

.quize-back {
  visibility: hidden;
  color: #4a505e;
  background-color: #fff;
  width: 140px;
  height: 40px;
  border-radius: 25px;
  box-shadow: 0 3px 13px 0 rgba(0,0,0,.13);
  border-style: none;
  cursor: pointer;
  font-weight: 500;
  font-size: 16px;
}

svg.icon.icon-qleft-arrow {
  margin-right: 5px;
}

svg.icon.icon-qcheck {
  margin-right: 5px;
}

.quize-next {
  color: #fff;
  background-color: #e8522f;
  width: 140px;
  height: 40px;
  border-radius: 25px;
  box-shadow: 0 6px 21px 0 #e8522f;
  border-style: none;
  cursor: pointer;
  font-weight: 500;
  font-size: 16px;
}

.quize-next:disabled {
  cursor: not-allowed;
}

.quize-right {
  background-color: #f7f7f7;
  width: 270px;
  border-radius: 0 5px 5px 0;
}

.quize-close {
  color: #232933;
  float: right;
  font-size: 28px;
  font-weight: bold;
  margin: 0;
  margin-right: 10px;
}

.quize-close:hover,
.quize-close:focus {
  color: #e8522f;
  text-decoration: none;
  cursor: pointer;
}

.quize-logo img {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  display: block;
  margin: 0 auto;
}

.quize-header h5 {
  color: #232933;
  display: block;
  width: 200px;
  margin: 0 auto;
  margin-top: 10px;
}

.quize-header p {
  color: #646464;
  display: block;
  width: 151px;
  margin: 0 auto;
  margin-top: 5px;
}

.quize-header hr {
  width: 230px;
  margin: 0 auto;
  margin-top: 10px;
}

p.quize-text {
  margin-top: 10px;
  width: 230px;
  font-size: 14px;
}

button.slider-button {
  background-size: 200% 100%,auto,100% 2px,100% 2px,100% 1px,100% 1px;
  background-image: linear-gradient(45deg,rgba(255,255,255,.0) 30%,rgba(255,255,255,.8),rgba(255,255,255,.0) 70%);
  background-repeat: no-repeat;
  background-position: -200% 0,0 0,0 0,0 100%,0 4px,0 calc(100% - 4px);
  animation: line 3s ease reverse infinite;
}

@keyframes line {
  100% {
    background-position: 500% 0,0 0,0 0,0 100%,0 4px,0 calc(100% - 4px);
  }

  20% {
    background-position: 200% 0,0 0,0 0,0 100%,0 4px,0 calc(100% - 4px);
  }

  0% {
    background-position: -200% 0,0 0,0 0,0 100%,0 4px,0 calc(100% - 4px);
  }
}

// QUize form
.quize-form-container {
  width: 100%;
  padding: 30px;
  padding-top: 10px;
  position: relative;
  margin: auto;
  display: flex;
  flex-direction: column;
}

.quize-form-icon {
  display: flex;
}

svg.icon.icon-done {
  margin: 0 auto;
}

.quize-form-title {
  display: flex;
  flex-direction: column;
}

.quize-form-title h3 {
  color: #363636;
  font-weight: 500;
  margin: 0 auto;
  margin-top: 20px;
}

.quize-form-wrap {
  display: flex;
  flex-direction: row;
}

.quize-form-left {
  display: flex;
  flex-direction: column;
  width: 50%;
  padding-top: 20px;
  padding-left: 8px;
  padding-right: 8px;
}

.quize-form-left p {
  color: #2e2e54;
  line-height: 1;
  padding: 0 10px 0 10px;
}

.quize-form-right {
  display: flex;
  flex-direction: column;
  width: 50%;
  padding-top: 20px;
  padding-left: 8px;
  padding-right: 8px;
}

.quize-form {
  display: flex;
  flex-direction: column;
}

.quize-form label {
  color: #2e2e54;
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 1.2px;
}

input.quize-form-phone {
  margin-top: 5px;
  padding: 15px 0;
  font-size: 16px;
  border: 1px solid #e8522f;
  border-radius: 5px;
  background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/PjwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB2ZXJzaW9uPSIxLjEiIGlkPSJDYXBhXzEiIHg9IjBweCIgeT0iMHB4IiB3aWR0aD0iMTZweCIgaGVpZ2h0PSIxNnB4IiB2aWV3Qm94PSIwIDAgMzQ4LjA3NyAzNDguMDc3IiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCAzNDguMDc3IDM0OC4wNzc7IiB4bWw6c3BhY2U9InByZXNlcnZlIj48Zz4gIDxnPiAgICA8Zz4gICAgICA8cGF0aCBkPSJNMzQwLjI3MywyNzUuMDgzbC01My43NTUtNTMuNzYxYy0xMC43MDctMTAuNjY0LTI4LjQzOC0xMC4zNC0zOS41MTgsMC43NDRsLTI3LjA4MiwyNy4wNzYgICAgIGMtMS43MTEtMC45NDMtMy40ODItMS45MjgtNS4zNDQtMi45NzNjLTE3LjEwMi05LjQ3Ni00MC41MDktMjIuNDY0LTY1LjE0LTQ3LjExM2MtMjQuNzA0LTI0LjcwMS0zNy43MDQtNDguMTQ0LTQ3LjIwOS02NS4yNTcgICAgIGMtMS4wMDMtMS44MTMtMS45NjQtMy41NjEtMi45MTMtNS4yMjFsMTguMTc2LTE4LjE0OWw4LjkzNi04Ljk0N2MxMS4wOTctMTEuMSwxMS40MDMtMjguODI2LDAuNzIxLTM5LjUyMUw3My4zOSw4LjE5NCAgICAgQzYyLjcwOC0yLjQ4Niw0NC45NjktMi4xNjIsMzMuODcyLDguOTM4bC0xNS4xNSwxNS4yMzdsMC40MTQsMC40MTFjLTUuMDgsNi40ODItOS4zMjUsMTMuOTU4LTEyLjQ4NCwyMi4wMiAgICAgQzMuNzQsNTQuMjgsMS45MjcsNjEuNjAzLDEuMDk4LDY4Ljk0MUMtNiwxMjcuNzg1LDIwLjg5LDE4MS41NjQsOTMuODY2LDI1NC41NDFjMTAwLjg3NSwxMDAuODY4LDE4Mi4xNjcsOTMuMjQ4LDE4NS42NzQsOTIuODc2ICAgICBjNy42MzgtMC45MTMsMTQuOTU4LTIuNzM4LDIyLjM5Ny01LjYyN2M3Ljk5Mi0zLjEyMiwxNS40NjMtNy4zNjEsMjEuOTQxLTEyLjQzbDAuMzMxLDAuMjk0bDE1LjM0OC0xNS4wMjkgICAgIEMzNTAuNjMxLDMwMy41MjcsMzUwLjk1LDI4NS43OTUsMzQwLjI3MywyNzUuMDgzeiIgZmlsbD0iI2U4NTIyZiIvPiAgICA8L2c+ICA8L2c+PC9nPjxnPjwvZz48Zz48L2c+PGc+PC9nPjxnPjwvZz48Zz48L2c+PGc+PC9nPjxnPjwvZz48Zz48L2c+PGc+PC9nPjxnPjwvZz48Zz48L2c+PGc+PC9nPjxnPjwvZz48Zz48L2c+PGc+PC9nPjwvc3ZnPg==);
  background-position: 20px;
  padding-left: 50px;
}

.quize-form-button {
  margin-top: 20px;
  font-size: 16px;
  padding: 15px 0;
  background-color: #e8522f;
  color: #fff;
  border-style: none;
  border-radius: 5px;
  box-shadow: 1.7px 5.8px 21px 0 #e8522f;
  font-weight: 400;
  letter-spacing: 1.2px;
  cursor: pointer;
}

.quize-form-policy {
  padding-top: 20px;
}

.quize-form-policy p {
  font-size: 12px;
  letter-spacing: 1px;
  color: #2e2e54;
}

.quize-form-policy a {
  color: #e8522f;
}

.quize-form-policy a:hover {
  text-decoration: none;
}

/*==========  Media ==========*/
@media (min-width: 992px) and (max-width: 1199.98px) {
  .quiz-content {
    width: 880px;
    height: 650px;
    flex-direction: row
  }
  
  .quize-left {
    width: 630px;
  }

  .quize-answers {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 20px 30px 20px 30px;
    width: 630px;
  }
}

@media (min-width: 768px) and (max-width: 991.98px) { 
  .quiz-content {
    width: 750px;
    height: 650px;
    flex-direction: row
  }
  
  .quize-left {
    width: 500px;
  }

  .quize-answers {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 20px 30px 20px 30px;
    width: 480px;
  }
}

@media (min-width: 576px) and (max-width: 767.98px) { 
  .quiz-content {
    width: 576px;
    height: auto;
    flex-direction: column;
    flex-wrap: wrap;
    flex-direction: column-reverse;
  }
  
  .quize-left {
    width: 576px;
  }

  .quize-right {
    width: 576px;
    padding-bottom: 20px;
    border-radius: 5px 5px 0 0;
  }

  .quize-answers {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 20px 30px 20px 30px;
    width: 480px;
  }

  .quize-question {
    font-size: 24px;
  }

  .quize-buttons {
    display: flex;
    justify-content: space-between;
    margin-left: 30px;
    margin-right: 30px;
    margin-bottom: 0px;
  }
}

@media (min-width: 481px) and (max-width: 575.98px) {
  .quiz-content {
    width: 470px;
    height: auto;
    flex-wrap: wrap;
    flex-direction: column-reverse;
    position: relative;
  }
  
  .quize-left {
    width: 470px;
  }

  .quize-question {
    font-size: 22px;
  }

  span.quize-answer-text {
    font-size: 14px;
  }

  .quize-answers {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 20px 30px 20px 30px;
    width: 460px;
  }

  .quize-form-wrap {
    display: flex;
    flex-direction: column;
  }
  
  .quize-form-left {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 410px;
  }

  .quize-form-left p {
    padding: 0;
    text-align: center;
  }
  
  .quize-form-right {
    display: flex;
    flex-direction: column;
    width: 470px;
  }

  .quize-form {
    display: flex;
    flex-direction: column;
    width: 410px;
  }

  .quize-buttons {
    display: flex;
    justify-content: space-between;
    margin-left: 30px;
    margin-right: 30px;
    margin-bottom: 0px;
  }
}

@media (min-width: 320px) and (max-width: 480.98px) {
  .quiz-content {
    width: 300px;
    height: auto;
    flex-wrap: wrap;
    flex-direction: column-reverse;
    position: relative;
  }
  
  .quize-left {
    width: 300px;
  }

  .quize-answers {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 20px 30px 20px 30px;
    width: 280px;
  }

  .quize-question {
    font-size: 18px;
  }

  span.quize-answer-text {
    font-size: 14px;
  }

  .quize-answer {
    width: 240px;
  }

  .quize-back {
    width: 110px;
    font-size: 16px;
  }
  
  .quize-next {
    width: 110px;
    font-size: 16px;
  }

  .quize-form-wrap {
    display: flex;
    flex-direction: column;
  }

  .quize-form-title h5 {
    text-align: center;
  }
  
  .quize-form-left {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 240px;
  }

  .quize-form-left p {
    padding: 0;
    text-align: center;
    width: 240px;
  }
  
  .quize-form-right {
    display: flex;
    flex-direction: column;
    width: 240px;
  }

  .quize-form {
    display: flex;
    flex-direction: column;
    width: 240px;
  }

  .quize-buttons {
    display: flex;
    justify-content: space-between;
    margin-left: 30px;
    margin-right: 30px;
    margin-bottom: 0px;
  }
}

// Modal info
.quizeModalInfo.modal h4 {
  text-align: center;
}

.quizeModalInfo.modal .modal-content-description {
  padding-bottom: 20px;
}

.quizeModalInfo.modal .modal-content {
  background-color: #fff;
  padding: 0 20px 0px 20px;
  border: 3px solid #e8522f;
  width: 450px;
  position: relative;
  margin: auto;
}

