/* Header container*/
.header-fixid {
  position: fixed;
  width: 100%;
  height: 95px;
  overflow: hidden;
  background: #fff;
  z-index: 1000;
}

.header-container {
  max-width: 1200px;
  min-width: 320px;
  height: 92px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

/* Logo */
.logo {
  width: 165px;
  height: 54px;
}

  .header-description {
    display: none;
  }

  .zakaz-zvonka {
    display: none;
  }

  .header-phone {
    display: none;
  }

  .work-time {
    display: none;
  }

  .header-map {
    display: none;
  }

  .header-container-2 {
    display: none;
  }

  .header-social {
    display: none;
  }

/* Contact */
.contact {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  font-size: 14px;
}

.contact a {
  color: #666;
}

.contact-left {
  display: flex;
  flex-direction: column;
  padding-right: 10px;
}

.contact-right {
  display: flex;
  flex-direction: column;
}

.header-whatsapp {
  font-size: 12px;
  margin-top: 20px;
  padding: 10px 20px;
  background: #4cd361;
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-content: center;
  align-items: center;
  color: #fff;
  font-weight: 700;
  border-style: none;
  cursor: pointer;
}

.header-whatsapp__text {
  margin-left: 10px;
}

i.fab.fa-whatsapp.fa-2x {
  font-weight: 300;
}

/*==========  Media Header  ==========*/
@media (min-width: 1025px) and (max-width: 1280px) {
  .contact {
    font-size: 12px;
    padding: 10px;
  }

  .logo {
    width: 135px;
    height: 44px;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .contact {
    font-size: 10px;
    padding: 10px;
  }

  .logo {
    width: 135px;
    height: 44px;
  }

  .perezvon-right {
    display: none;
  }
}

@media (min-width: 481px) and (max-width: 767px) {
  .logo {
    padding-top: 0px;
    margin-top: 10px;
  }

  .header-fixid {
    display: none;
  }

  .header-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 5px;
  }

  .header-container-2 {
    max-width: 1200px;
    min-width: 320px;
    height: 245px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .header-description {
    font-size: 12px;
    color: #000;
    max-width: 210px;
    align-items: center;
    display: inline-block;
    margin-top: 10px;
  }

  .header-description p {
    margin: 0;
  }

  .work-time {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 5px;
    color: #000;
    font-size: 12px;
  }

  .header-social {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }

  .header-social a {
    margin: 5px;
  }

  .work-time p {
    margin: 0;
  }

  .zakaz-zvonka {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 10px;
  }

  .zakaz-zvonka p {
    border: 2px solid #e8522f;
    font-size: 12px;
    color: #000;
    border-radius: 10px;
    font-weight: bold;
    padding: 5px 10px;
    margin-top: 0px;
    margin: 0;
  }

  .header-phone {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    font-weight: bold;
    margin-top: 10px;
  }

  .header-phone p {
    margin: 0;
  }

  p.header-map {
    display: flex;
    flex-direction: row;
    justify-content: center;
    font-size: 12px;
    font-weight: bold;
    color: #000;
    margin-top: 5px;
  }

  .header-phone a {
    color: #000;
  }

  b.kazan-border {
    border-bottom: 1px dotted #000;
  }

  .contact {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    font-size: 12px;
    display: none;
  }

  .contact-left {
    padding-right: 10px;
  }

  .perezvon-right {
    display: none;
  }

  .to-top {
    display: none;
  }

  .silver-line {
    display: none;
  }

}

@media (min-width: 320px) and (max-width: 480px) {
  .logo {
    padding-top: 0px;
    margin-top: 10px;
  }

  .header-fixid {
    display: none;
  }

  .header-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 5px;
  }

  .header-container-2 {
    max-width: 1200px;
    min-width: 320px;
    height: 310px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  b.kazan-border {
    border-bottom: 1px dotted #000;
  }

  .header-description {
    font-size: 12px;
    color: #000;
    max-width: 210px;
    align-items: center;
    display: inline-block;
    margin-top: 10px;
  }

  .header-description p {
    margin: 0;
  }

  .header-social {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }

  .header-social a {
    margin: 5px;
  }

  .work-time {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 5px;
    color: #000;
    font-size: 12px;
  }

  .work-time p {
    margin: 0;
  }

  .zakaz-zvonka {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 10px;
  }

  .zakaz-zvonka p {
    border: 2px solid #e8522f;
    font-size: 12px;
    color: #000;
    border-radius: 10px;
    font-weight: bold;
    padding: 5px 10px;
    margin-top: 0px;
    margin: 0;
  }

  .header-phone {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    font-weight: bold;
    margin-top: 10px;
  }

  .header-phone p {
    margin: 0;
  }

  p.header-map {
    display: flex;
    flex-direction: row;
    justify-content: center;
    font-size: 12px;
    font-weight: bold;
    color: #000;
    margin-top: 5px;
  }

  .header-phone a {
    color: #000;
  }

  .contact {
    display: none;
  }

  .contact-left {
    padding-right: 10px;
  }

  .perezvon-right {
    display: none;
  }

  .to-top {
    display: none;
  }

  .silver-line {
    display: none;
  }
}

@media (min-width: 0px) and (max-width: 319px) {
  .logo {
    padding-top: 0px;
  }

  .header-fixid {
    display: none;
  }

  .header-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 5px;
  }

  .header-container-2 {
    max-width: 1200px;
    min-width: 320px;
    height: 245px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .header-description {
    font-size: 12px;
    color: #000;
    max-width: 210px;
    align-items: center;
    display: inline-block;
    margin-top: 10px;
  }

  .header-description p {
    margin: 0;
  }

  .header-social {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }

  .header-social a {
    margin: 5px;
  }

  .work-time {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 5px;
    color: #000;
    font-size: 12px;
  }

  .work-time p {
    margin: 0;
  }

  .zakaz-zvonka {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 10px;
  }

  .zakaz-zvonka p {
    border: 2px solid #e8522f;
    font-size: 12px;
    color: #000;
    border-radius: 10px;
    font-weight: bold;
    padding: 5px 10px;
    margin-top: 0px;
    margin: 0;
  }

  .header-phone {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    font-weight: bold;
    margin-top: 10px;
  }

  .header-phone p {
    margin: 0;
  }

  p.header-map {
    display: flex;
    flex-direction: row;
    justify-content: center;
    font-size: 12px;
    font-weight: bold;
    color: #000;
    margin-top: 5px;
  }

  .header-phone a {
    color: #000;
  }

  b.kazan-border {
    border-bottom: 1px dotted #000;
  }

  .contact {
    display: none;
  }

  .contact-left {
    padding-right: 10px;
  }

  .perezvon-right {
    display: none;
  }

  .to-top {
    display: none;
  }

  .silver-line {
    display: none;
  }
}