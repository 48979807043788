.main-delivery {
  max-width: 1200px;
  min-width: 320px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  flex-direction: row;
  margin-top: 50px;
}

/* left */
.delivery-left {
  display: flex;
  flex-direction: column;
  width: 50%;
}

.delivery-title {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

.delivery-title h2 {
  margin: 0;
  font-weight: bold;
  font-size: 36px;
}

span.delivery-line {
  position: relative;
  display: flex;
  flex-direction: row;
  margin-right: 20px;
  margin-bottom: 20px;
  padding: 0 0 20px;
  border-bottom: 1px #e2e2e2 solid;
  font-weight: bold;
  text-transform: capitalize;
  font-size: 20px;
}

span.delivery-line:before {
  position: absolute;
  bottom: -3px;
  left: 20px;
  width: 35px;
  height: 5px;
  content: "";
  display: inline-block;
  background: #e7512f;
}

span.delivery-line:after {
  position: absolute;
  bottom: -1px;
  left: 0;
  width: 80px;
  height: 1px;
  content: "";
  display: inline-block;
  background: #e7512f;
}

.delivery-description {
  display: flex;
  flex-direction: row;
  margin-top: 5px;
}

/* right */
.delivery-right {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 50%;
  padding: 10px;
}

.delivery-right img {
  width: 555px;
  height: 370px;
}

@media (min-width: 481px) and (max-width: 767px) {
  .main-delivery {
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin-top: 20px;
  }

  .delivery-left {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    padding: 5px;
  }

  .delivery-right {
    display: none;
  }
}

@media (min-width: 320px) and (max-width: 480px) {
  .main-delivery {
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin-top: 20px;
  }

  .delivery-left {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    padding: 5px;
  }

  .delivery-right {
    display: none;
  }
}

@media (min-width: 0px) and (max-width: 319px) {
  .main-delivery {
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin-top: 20px;
  }

  .delivery-left {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    padding: 5px;
  }

  .delivery-right {
    display: none;
  }
}